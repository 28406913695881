@use '../../../../styles/vars.scss';

.welcome {
    padding-top: 220px;
    display: flex;
    max-width: 940px;
    margin: auto;
    align-items: center;

    &-preda {
        max-width: 470px;
    }

    &-prez {
        max-width: 470px;

        &-p {
            line-height: 150%;
            font-size: 105%;
        }
        @keyframes animateHeart {
            // scale down and scale up faster in irregular intervals to get the throbbing effect
            0% {
              transform: rotate(0deg) scale(0.8);
            }
            5% {
              transform: rotate(1deg) scale(0.9);
            }
            10% {
              transform: rotate(0deg) scale(0.8);
            }
            15% {
              transform: rotate(-1deg) scale(1);
            }
            50% {
              transform: rotate(0deg) scale(0.8);
            }
            100% {
              transform: rotate(1deg) scale(0.8);
            }
        }
        &-heart {
            margin:0px;
            font-size: 200%;
            transform: rotate(45deg);
            // animation: <animation-name> <animation-duration> <animation-iteration-count>
            animation: animateHeart 1.2s infinite;
            font-family: "BonusHearts";
        }
        &-buttons {
            

            &-addbot,
            &-login {
                align-items: center;
                color: white;
                text-decoration: none;
                font-size: 180%;
                width: 150px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                white-space: nowrap;
                margin: auto;
            }
            
        }
    }
}

@media (max-width: 640px) {
    .welcome {
        padding-top: 64px;
        width: 100%;
        flex-direction: column;

        &-preda {
            width: 50%;
        }

        &-prez {
            width: 95%;

        }
    }
}