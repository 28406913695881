@use '../../../styles/vars';
.header {
  
  z-index:7;
  position: fixed;
  top: 0;
  width: 100%; 
  height: 64px;
  margin-right: 0%;
  margin-left:0%;
  display: flex;
  align-items:center;
  
  &-links {

    position: relative;
    /*right:4em;*/
    font-size: 150%;
    width:100%;
    display: flex;
    justify-content: center;

    &-item {
      font-family: "DeadSpaceTitleFont";
      //font-family: Tahoma, Geneva, Kalimati, sans-serif;
      //font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      color: #36393F;
      text-decoration:none;
      margin:1%;
    }

    &-item:link, &-item:visited, &-item:active {
      color: white;
      text-decoration:none; 
    }

    &-item:hover {
      color: whitesmoke;
      text-decoration:none; 
    }

  }

  &-logo {
    width: 64px;
    height: 64px;
  }
  
}
@media (max-width: vars.$bp-phones) {
  
  .header {
    position: fixed;
    top: 0;
    width: 100%; 
    height: 32px;
    margin-right: 0%;
    margin-left:0%;
    display: flex;
    
    &-links {
  
      position: relative;
      right:0em;
      font-size: 140%;
      width:100%;
      display: flex;
      justify-content: center;
      font-size: 100%;
      &-item {
        font-family: Tahoma, Geneva, Kalimati, sans-serif;
        color: white;
        text-decoration:none;
        margin:auto;
      }
  
      &-item:link, &-item:visited, &-item:active {
        color: white;
        text-decoration:none; 
      }
  
      &-item:hover {
        color: white;
        text-decoration:none; 
      }
  
    }
  
    &-logo {
      width: 32px;
      height: 32px;
    }
    
  }
  
}