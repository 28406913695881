
@use '../../../styles/vars';
.app {
    text-align: center;
    overflow-x: hidden;
}
.login {
    padding-top: 12%;
    max-width: 470px;
    margin: auto;
    &-discord {
        &-link {
            color:white;
            text-decoration: none;
        }
        &-link:hover {
            color:yellow;
            text-decoration: none;
        }
    }
}
@media (max-width: 640px) {
    .login {
        padding-top: 64px;
        width: 95%;
        
    }
}