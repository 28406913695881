//body {
//    background-image: url("");
//    background-repeat: no-repeat;
//    min-height: 100%;
//}


.app {
    min-width: 100%;
    min-height: 100%;
}
.serv {
    padding-top: 64px;
}
.dashboard {
    &-private {
        padding-top: 64px;
    }
}
@media (max-width: 640px) {
    .dashboard {
        &-private {
            padding-top: 32px;
        }
    }
}
//.temoignages {
//    padding-top: 130px;
//    align-items: center;
//    width: 100%;
//    height: 100%;
//    display: flex;
//    justify-content: space-evenly;
//    &-page {
//        width: 80%;
//    }
//    &-a, &-b, &-c {
//        display: flex;
//        align-items: center;
//        background-color: #B5E51C;
//        &-img {
//            width: 128px;
//            height: 128px;
//            margin-left:2%;
//        }
//        &-div {
//            
//            text-align: center;
//            justify-content: space-evenly;
//            margin-left:2%;
//            margin-right:2%;
//        }
//    }
//    &-b {
//        background-color: #FFFFB4;
//    }
//}
//@media (max-width: vars.$bp-phones) {
//    .temoignages {
//        &-a, &-b, &-c {
//            &-img {
//                display:none;
//            }
//        }
//    }
//}
