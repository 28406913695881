@use '../../../styles/vars';
.footer {
  z-index:7;
  font-size: 130%;
  height: 64px;
  //color: #fff;
  //background-color: aqua;
  position: fixed;
  bottom: 0;
  width: 100%;
  
  justify-content: center;
  &-items {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    max-width:600px;
    margin:auto;
    &-link {
      //background-color: aqua;
      font-family: "DeadSpaceTitleFont";
      //font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      //font-family: Tahoma, Geneva, Kalimati, sans-serif;
      //font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      color: white;
      text-decoration:none; 
    }
    &-img {
      max-width: 32px;
      margin-right: 5px;
    }
  }
  &-items-link:link {
    color: white;
    text-decoration:none; 
  }
  &-item-t:visited {
    color: white;
    text-decoration:none; 
  }
  &-item:hover {
    color: whitesmoke;
    text-decoration:none; 
  }
  &-item:active {
    color: white;
    text-decoration:none; 
  }
}
@media (max-width: 640px) {
  .footer {
    font-size: 100%;
    height: 32px;
    &-items {
      &-link {
        font-family: Tahoma, Geneva, Kalimati, sans-serif;
      }
    }
    
  }
}