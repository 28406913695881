@use '../../../styles/vars';
.cgu {
    padding-top: 64px;
    width:900px;
    margin: auto;
    display: flex;
    flex-direction: column;
    &-a, &-b, &-c {
        display: flex;
        flex-direction: row;
        font-size: 82%;
        padding-left: 1%;
        padding-right: 1%
    }
}
@media (max-width: 640px) {
    .cgu {
        padding-top: 32px;
        width: 95%;
        &-titles {
            font-size: 100%;
        }
        &-articles {
            font-size: 64%;
        }
        &-a, &-b, &-c {
            display: flex;
            flex-direction: column;
            font-size: 82%;
            padding-left: 1%;
            padding-right: 1%
        }
    }
}