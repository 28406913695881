.blog {
    padding-top: 65px;
    &-article {
        &-img {
            width: 528px;
        }
        &-titre {
            margin-top: 50px;
        }
        &-text {
            width: 800px;
            margin-right:auto;
            margin-left:auto;
            margin-bottom: 50px;
        }
        a:visited, a:link, a:active {
            color: rgb(0, 174, 255);
            text-decoration:none;
        }
        

    }
    &-link {
        
        background-color: rgba(0, 0, 0, 0.507);
        width: 150px;
        height: 50px;
        margin-left: auto;
        margin-right: auto;
        display:flex;
        justify-content: center;
        align-items: center;
        &-allposts:visited, &-allposts:link, &-allposts:active {
            color: rgb(0, 174, 255);
            text-decoration:none;
        }
        &-allposts:hover, a:hover {
            color: rgb(255, 255, 255);
            text-decoration:none;
        }
    }
    
}