.blog {
    padding-top: 65px;
    &-article {
        &-titre {
            margin-top: 50px;
        }
        &-text {
            width: 528px;
            margin-right:auto;
            margin-left:auto;
            margin-bottom: 300px;
        }
        h2, a:visited, a:link, a:active {
            color: rgb(255, 255, 255);
            text-decoration:none;
        }
        h2:hover, a:hover {
            color: rgb(0, 174, 255);
            text-decoration:none;
        }
    }
    &-img {
        width: 528px;
    }
}
@media (max-width: 640px) {
    .blog {
        padding-top: 32px;
        &-article {
            width:98%;
            &-titre {
                margin-top: 50px;
                margin-right:auto;
                margin-left:auto;
            }
            &-text {
                width: 98%;
                margin-right:auto;
                margin-left:auto;
                margin-bottom: 300px;
                
                display: contents;
            }
        }
        &-img {
            max-width: 98%;
            margin-right:auto;
            margin-left:auto;

        }
    }
}