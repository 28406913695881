// Style the body in the App component
// because semantic ui overtakes the styles in src/styles/index.scss
body {
  //font-family: 'neon' sans-serif;
  background-color: #28292c; //#B5E51C #2A2B2A
  //width: 1920px;
  //height: 1080px;
  margin:0%;
  color:white;
}
.app {
  width:100%;
}

@font-face {
  font-family: "Space_Galaxy";
  src: local("Space_Galaxy"),
  url("../../fonts/ca.ttf") format("truetype");
}
@font-face {
  font-family: "Space_Pontiff";
  src: local("Space_Pontiff"),
  url("../../fonts/Space_Pontiff.ttf") format("truetype");
}
@font-face {
  font-family: "DeadSpaceTitleFont";
  src: local("DeadSpaceTitleFont"),
  url("../../fonts/DeadSpaceTitleFont.ttf") format("truetype");
}
@font-face {
  font-family: "BonusHearts";
  src: local("BonusHearts"),
  url("../../fonts/BonusHearts.ttf") format("truetype");
}