
@use '../../../styles/vars';
.app {
    text-align: center;
    overflow-x:hidden;
    //font-family: Tahoma, Geneva, Kalimati, sans-serif;
    font-family: "ca";

}
/*.dashboard {
    padding-top: 65px;
    
    &-public {
        margin: auto;
        max-width:600px;
        display:flex;
        flex-direction: column;
        align-items: center;
        &-preda {
            width: 96px;
        }
        &-p {
            line-height: 200%;
        }
        &-botavatar {
            max-width: 128px;
            margin-top: 16px;
            margin-bottom: 16px;
        }
        &-addbot, &-login {
            font-size: 200%;
            color: white;
            text-decoration:none;
            
            font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
            
        }
        &-addbot:link, &-addbot:visited, &-addbot:active {
            color: white;
        }
    }
}*/
