@use '../../../styles/vars';
.team {
    padding-top: 12%;
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &-img {
        
        width:420px;
    }
    &-users {
        
        display: inline;
        &-p {
            width:130%;
        }
    }
}
@media (max-width: 640px) {
    .team {
        padding-top: 64px;
        width: 95%;
        flex-direction: column;
        &-img {
            width:50%;
        }
        &-users {
            display: inline;
            &-p {
                width:100%;
                font-size: 71%;
            }
        }
    }
}