//body {
//    background-image: url("");
//    background-repeat: no-repeat;
//    min-height: 100%;
//}

@use '../../../styles/vars';
.app {
    min-width: 100%;
    min-height: 100%;
}
.commands {
    
    display: flex;
    flex-direction: column; 
    flex-wrap:nowrap;
    justify-content: space-between;
    max-width:500px;
    margin:auto;
    padding-top: 65px;
    &-img {
        max-width: 100%;
    }
    &-items {
        //background-color: red;
        display:flex;
        flex-direction: row;
        width:100%;
        align-items: center;
        font-size: 80%;
        margin: auto;
        &-command {
            width:25%;
            text-align: left;
        }
        &-info {
            width:75%;
            text-align: right;
        }
    }
}
@media (max-width: 640px) {
    .commands {
        padding-top: 64px;
        width:95%;
        &-items {
            font-size: 70%;
        }
    }
}