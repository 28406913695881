//body {
//    background-image: url("");
//    background-repeat: no-repeat;
//    min-height: 100%;
//}

.app {
    min-width: 100%;
    min-height: 100%;
}
.config {
    padding-top: 64px;
    &-h3 {
        padding-top: 64px;
    }
    width:800px;
    margin:auto;
    &-sanction, &-prev {
        &-option {
            &-input {
                width:24px;
                height:24px;
            }
        }
    }
    &-vote, &-prev {
        align-items: center;
        &-toggle {
            margin:0px;
            appearance: none;
            padding: 16px 30px;
            border-radius: 16px;
            background: radial-gradient(circle 12px, white 100%, transparent calc(100% + 1px)) rgb(121, 121, 121) -14px;
            transition: 0.3s ease-in-out;
        }
        &-toggle:checked {
            background-color: dodgerBlue;
            background-position: 14px;
        }
    }
    &-box {
        
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
    }
    &-buttons {
        padding-top:10px;
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        margin:auto;
        width:500px;
        &-update, &-back {
            background-color: transparent;
            padding: 0px;
            margin: 0px;
            border: 0px;
            text-decoration: none;
            color: white;
            text-decoration: none;
            font-size: 20px;
            cursor: pointer;
        }
        &-back:link, &-update:link, &-back:visited, &-update:visited, &-back:active, &-update:active, &-back:hover, &-update:hover {
            color: white;
            text-decoration: none;
            cursor: pointer;
        }
    }
}
@media (max-width: 640px) {
    .config {
        padding-top: 32px;
        width: 95%;
        font-size: 80%;
        &-h3 {
            padding-top: 0px;
            font-size: 80%;
        }
        &-buttons {
            width: 100%;
        }
        /*&-buttons {
            padding-bottom: 32px;
        }*/
    }
}